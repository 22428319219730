import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import TeamImage from '../images/teamImage.svg'

const Container = tw.div`relative lg:py-10 m-5 lg:ml-24 lg:mr-24`
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`
const ImageColumn = tw(Column)`md:w-6/12 lg:w-5/12 flex-shrink-0 h-80 md:h-auto`

const TextColumn = styled(Column)((props) => [
  tw`md:w-6/12 mt-8 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-8 lg:mr-16 md:order-first`
    : tw`md:ml-8 lg:ml-16 md:order-last`,
])
const SectionHeading = tw.h2`mt-4 text-4xl sm:text-5xl font-black tracking-wide text-center`

const Image = styled.div`
  ${tw`bg-center flex justify-center items-center lg:mt-10`}
  img {
    ${tw`w-full object-cover h-team lg:h-teamLg rounded-sm`}
  }
`

const TextContent = tw.div`lg:py-24 lg:ml-12 `

const Heading = tw(
  SectionHeading
)` font-inter text-center mb-5 lg:text-center font-bold text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight ml-3`
const Description = tw.p`text-justify lg:text-justify md:text-justify md:text-left text-sm md:text-base lg:text-lg font-light leading-6 lg:leading-9 text-black m-5 `

const About = () => {
  return (
    <>
      <Container id='about'>
        <Heading>Why COAM?</Heading>
        <Image>
          <img src={TeamImage} alt='meet the team' />
        </Image>
        <Description>
          We started COAM in response to customer frustration - we heard
          countless stories about the complexities that Sellers faced when
          trying to grow their businesses on Jumia and Konga. Whether it was
          uploading products or understanding the difference between SEO and
          keyword optimisation or how to create effective marketing campaigns,
          we kept hearing that sellers like you need help. We started COAM to
          make your experience selling online as seamless as possible. And we’re
          the first to admit that selling online isn’t easy. With more than 10
          years of experience selling millions of products across several
          countries and two continents, we learned the hard way. Don’t be afraid
          to ask for help. Contact us today to get help grow your e-commerce
          presence in Nigeria. Don’t learn the hard way.
        </Description>
      </Container>
    </>
  )
}

export default About
