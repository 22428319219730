import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import Cardslider from '../components/Cardslider'

const Container = styled.div`
  ${tw` bg-white p-5 font-sans`}
`

const SectionHeader = tw.h1`text-center m-3 font-bold text-3xl sm:text-4xl lg:text-5xl`
const Content = tw.div`max-w-screen-xl overflow-hidden mx-auto`

const HeadingDescription = tw.p`text-base font-light sm:px-20 lg:w-9/12 xl:w-3/4 text-center leading-7 mt-2 sm:mt-4 flex m-auto items-center justify-items-center`

const Services = () => {
  return (
    <div id='services'>
      <Container>
        <Content>
          <SectionHeader>
            Our Services
          </SectionHeader>
          <HeadingDescription>
            One size never fits all: we believe in finding a tailored approach
            that helps your business reach customers most effectively. Our three
            tiers offer integrated, performance-driven approaches to helping
            your business succeed on Nigeria’s leading e-commerce platforms.
          </HeadingDescription>
          <Cardslider />
        </Content>
      </Container>
    </div>
  )
}

export default Services
