import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import Modal from 'react-modal'
import reportWebVitals from './reportWebVitals'

const GA = require('./helpers/googleAnalytics')
Modal.setAppElement('#root')
window.aslanUtils = {
  GA: 'UA-208030938-3',
}
GA.init()
ReactDOM.render(<App />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
