import React, { useState, useEffect } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import Carousel from './Carousel';
import background from '../images/coam-background.png';
import Navbar from './Navbar';

import Dropdown from './Dropdown';

const Container = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center; */
  ${tw`    relative  bg-center bg-cover h-screen min-h-screen   `}
  background-image: url(${background});
  button {
    ${tw`capitalize w-40 font-bold text-white  h-14 bg-darkBrown  mt-4 cursor-pointer text-base   `}
  }
  .primary-button {
    ${tw`flex justify-center capitalize    `}
  }
`;

// const Navigate = tw.div`capitalize w-40 font-bold text-white  h-14 bg-darkBrown  mt-4 cursor-pointer text-base`;

const OpacityOverlay = tw.div`absolute inset-0 bg-black opacity-75`;
const HeroContainer = tw.div` z-20 relative  sm:px-8 mx-auto h-full flex flex-col`;

const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center mb-10`;

const Drop = tw.div`absolute inset-y-20 right-4 `;

const Heading = styled.h1`
  ${tw`font-extrabold capitalize text-center text-white  lg:text-5xl text-2xl m-4 md:text-6xl `}
`;

const SubHeading = styled.p`
  ${tw`font-inter text-center text-white capitalize`}
  .main_text {
    ${tw` lg:w-5/12 lg:text-lg  md:text-xl md:w-3/5 w-80 text-lg m-auto`}
  }
`;

const Hero = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const hideMenu = () => {
      if (window.innerWidth > 768 && isOpen) {
        setIsOpen(false);
      }
    };
    window.addEventListener('resize', hideMenu);

    return () => {
      window.removeEventListener('resize', hideMenu);
    };
  }, [isOpen]);
  return (
    <div>
      <Container>
        <OpacityOverlay />
        <HeroContainer>
          <Navbar toggle={toggle} isOpen={isOpen} />
          <Drop>
            <Dropdown isOpen={isOpen} toggle={toggle} />
          </Drop>
          <Content>
            <Heading>
              <h1>We build e-commerce businesses </h1>
            </Heading>

            <SubHeading>
              <div className='main_text'>
                <p className='font-light text-base'>
                  Get started with COAM to increase your e-commerce sales on
                  Jumia or Konga, reach more customers, improve conversion and
                  scale your business’ digital presence from our experts
                </p>
              </div>
            </SubHeading>

            <div className='primary-button'>
              <button>
                <a href='#getstarted'>Get Started</a>
              </button>
            </div>
          </Content>
        </HeroContainer>
      </Container>
      <Carousel />
    </div>
  );
};

export default Hero;
