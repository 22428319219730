import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import COAM from '../images/COAM-Logo.svg';
// import Linkedin from '../icons/linkedin.svg';
// import Instagram from '../icons/instagram.svg';
// import Twitter from '../icons/twitter.svg';
// import Facebook from '../icons/facebook.svg';

const DropLinks = styled.div`
  ${tw`    h-4/6 bg-gray-900 text-white rounded lg:hidden`}
`;
const DropLink = styled.a`
  ${tw`  leading-10 capitalize font-bold`}
`;

const COAMLOGO = tw.div`w-40 h-20 p-5 m-auto mt-24`;
const COAMCONTENT = tw.div`w-3/5 m-auto font-sans font-normal text-base text-lightBrown capitalize`;
// const SOCIALS = tw.div` flex items-center justify-center  `;

const Dropdown = ({ isOpen, toggle }) => {
  return (
    <main>
      <AnimatePresence>
        {isOpen && (
          <motion.aside
            initial={{ width: 0 }}
            animate={{ width: 252, height: 968 }}
            exit={{ width: 0 }}>
            <DropLinks onClick={toggle} key={1}>
              <motion
                initial='hidden'
                animate='visible'
                variants={{
                  hidden: { x: 0, opacity: 0 },
                  visible: {
                    x: 10,
                    opacity: 1,
                    transition: { duration: 0.5 },
                  },
                }}
                whileHover={{
                  scale: [1, 1.1, 1],
                  transition: { duration: 0.4 },
                }}>
                <a href='#services'>
                  <DropLink>
                    <div className='text-white text-lg p-4'>services</div>
                    {/* <div className='text-white text-lg'>home</div>
                    <div className='text-white text-lg'>home</div> */}
                  </DropLink>
                </a>
                <a href='#about'>
                  <DropLink>
                    <div className='text-white text-lg p-4'>about us</div>
                  </DropLink>
                </a>
                <a href='#getstarted'>
                  <DropLink>
                    <div className='text-white text-lg p-4'>get started</div>
                  </DropLink>
                </a>
                <COAMLOGO>
                  <img src={COAM} alt='coam' />
                </COAMLOGO>
                <COAMCONTENT>
                  <p className='text-center'>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting
                  </p>
                </COAMCONTENT>
                {/* <SOCIALS>
                  <div className='m-4'>
                    <img src={Linkedin} alt='linkedin' />
                  </div>

                  <div className='m-4'>
                    <img src={Instagram} alt='Instagram' />
                  </div>

                  <div className='m-4'>
                    <img src={Twitter} alt='Twitter' />
                  </div>

                  <div className=''>
                    <img src={Facebook} alt='Facebook' />
                  </div>
                </SOCIALS> */}
              </motion>
            </DropLinks>
          </motion.aside>
        )}
      </AnimatePresence>
    </main>
  );
};

export default Dropdown;
