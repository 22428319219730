import React, { useState, useEffect } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from '../components/misc/Headings.js';
// import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as Phone } from '../images/phone-call.svg';
import { ReactComponent as EmailIllustrationSrc } from '../images/email.svg';
import kongaLogo from '../images/logo1.svg';
import jumiaLogo from '../images/jumia.svg';

import emailjs from 'emailjs-com';
const GA = require('../helpers/googleAnalytics');



 const PrimaryButtonBase = tw.button`px-8 py-3 font-bold rounded bg-blue-500 text-gray-100 hocus:bg-darkBrown
                                      hocus:text-gray-200 focus:ring focus:outline-none transition duration-300 `;

const Container = tw.div`relative py-28 m-5`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;

const ImageColumn = tw(
  Column
)`md:w-6/12 lg:h-auto rounded-full flex-shrink-0 h-80 pl-4 pr-4 lg:rounded-full md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12  md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw` bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
// const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
// const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-black font-thin	`
const Number = tw.p`text-center lg:inline-block lg:mr-10 md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-black font-thin	`;
const Email = tw.p`text-center md:text-left lg:inline-block text-sm md:text-base lg:text-lg font-medium leading-relaxed text-black font-thin	`;

const AlertContainer = tw.p`bg-green-500 pt-5 border	`;

const IconContainer = styled.div`
  ${tw`inline-block mt-4 lg:mt-8 lg:inline-block lg:mr-2  `}
  svg {
    ${tw`w-5 h-4`}
  }
`;

const Form = tw.form` md:mt-10 text-sm flex flex-col max-w-xl mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-6 focus:outline-none border-black text-lg transition duration-300 hocus:border-blue-500`;
const Textarea = styled(Input).attrs({ as: 'textarea' })`
  ${tw`h-24 mt-6 border-b-2 border-black text-lg`}
`;

const SubmitButton = tw(PrimaryButtonBase)`w-1/4 mt-10  bg-darkBrown`;

const HeadingContainer = tw.div``;
// const Subheading = tw(SubheadingBase)`text-center text-gray-100 mb-4`;
//  const SectionHeading = tw.h2`text-4xl sm:text-5xl font-black tracking-wide text-center`
const SectionDescription = tw.p`mt-4 text-sm md:text-base lg:text-lg font-medium leading-relaxed max-w-xl`;

const Heading = tw(SectionHeading)``;
const Description = tw(
  SectionDescription
)`mx-auto text-center  text-base font-light`;
const Logos = tw.div`mx-auto text-center text-gray-600 w-96 mt-6 flex`;
const LogoContainer = tw.div`w-40 lg:w-96 flex`;

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  heading = <>Get Started</>,
  description = 'What platform are you looking to sell on?',
  number = '0845796335920',
  email = 'ehjjsaoakjjua@gmail.com',
  submitButtonText = 'Submit',
  formAction = '#',
  formMethod = 'get',
  textOnLeft = true,
}) => {
  const [showMessage, isShowing] = useState(false);
  const messageShowing = () => isShowing(true);
  const messageNotShowing = () => isShowing(false);

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_d3sqabj',
        'template_r4x0vgn',
        e.target,
        'user_3upRs0j9qJy7MUL32vi62'
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log('Success!');
          messageShowing();

          // refreshPage()
        },
        (error) => {
          console.log('Failure!');
          console.log(error.text);
          messageNotShowing();
        },
        e.target.reset
      );
  }

  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container>
      {GA.logPageView()}
      <HeadingContainer>
        {/* {subheading && <Subheading>{subheading}</Subheading>} */}
        <Heading>{heading}</Heading>
        <Description>{description}</Description>
        <Logos>
          <LogoContainer>
            {' '}
            <img src={kongaLogo} style={{ marginRight: '35px' }} />
          </LogoContainer>
          <LogoContainer>
            {' '}
            <img
              src={jumiaLogo}
              style={{ height: '80px', marginRight: '35px' }}
            />
          </LogoContainer>
        </Logos>
      </HeadingContainer>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc='https://images.unsplash.com/photo-1532526338225-bc66ea49a9f2?ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8dGVsZXBob25lfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60' />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {/* <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
              <IconContainer>
              <Phone/>
              </IconContainer>
            
            
            {number && <Number>{number}</Number>}
            <IconContainer>
              <EmailIllustrationSrc/>
              </IconContainer>
            {email && <Email>{email}</Email>} */}
            <Form onSubmit={sendEmail}>
              <Input type='text' name='from_name' placeholder='Name' />
              <Input type='email' name='from_email' placeholder='Email' />
              <Textarea name='message' placeholder='Your Message' />

              {showMessage ? (
                <p style={{ marginTop: '10px' }}>Your request has been sent.</p>
              ) : (
                <div></div>
              )}

              <SubmitButton type='submit' value='Send'>
                {submitButtonText}
              </SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
