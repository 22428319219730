import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import { cards } from './cards'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Container = tw.div`relative `
const Content = tw.div`max-w-screen-xl  mx-auto pb-16 lg:relative lg:bottom-44 md:relative md:bottom-44`

// const Row = styled.div`
//   ${tw`  lg:relative lg:bottom-28 md:relative md:bottom-28 `}
// `;

const Row = styled.div`
  ${tw`  `}
`

const CardSlider = styled(Slider)`
  ${tw`mt-16 `}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1 text-black   `}
  }
`
// const Card = tw.div`h-full flex! flex-col  max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none`;

const Card = styled.div`
  ${tw`  w-72 bg-white rounded-lg p-6 m-4 h-1/3 md:w-52 lg:w-72 md:p-4 lg:p-8 lg:m-0 md:m-0  2xl:m-auto `}
`

const Svg = styled.div`
  ${tw`flex justify-center items-center  mb-4 `}
`

const Title = styled.div`
  ${tw`flex justify-center items-center font-bold text-size leading-line md:text-2xl lg:text-2xl `}
`

const Desc = styled.div`
  ${tw`flex justify-center items-center font-thin text-center  ml-4 mt-4 text-base font-sans md:text-lg lg:text-base h-60 md:h-full lg:h-60 leading-7 lg:leading-7 `}
`

const Carousel = () => {
  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,

    slidesToShow: 4,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slide: '.slick-slideshow__slide',
          // slidesToShow: 3,
          infinite: true,
          centerMode: true,
          centerPadding: '30px',
        },
      },
    ],
  }
  return (
    <Container>
      <Content>
        <CardSlider {...settings}>
          {cards.map((card, index) => {
            const { img, title, desc } = card
            return (
              <Row>
                <Card
                  className='border-2 border-grey md:border-0 lg:shadow-lg'
                  key={index}
                >
                  <Svg>
                    <img src={img} alt='' />
                  </Svg>
                  <Title>{title}</Title>
                  <Desc>{desc}</Desc>
                </Card>
              </Row>
            )
          })}
        </CardSlider>
      </Content>
    </Container>
  )
}

export default Carousel
