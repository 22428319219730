import React from 'react';

import tw from 'twin.macro';
import styled from 'styled-components';
import menubar from '../icons/hamburger.svg';
import closeicon from '../icons/close.svg';
import COAM from '../images/COAM-Logo.svg';
import COAMICON from '../images/COAM-Icon.svg';

const Nav = styled.nav`
  ${tw` flex justify-between items-center     `}
  .menubar {
    ${tw`h-12 w-12 ml-auto mt-4 block cursor-pointer rounded md:hidden lg:hidden  md:h-12 w-12`}
  }
  button {
    ${tw` ml-8 w-28 h-16 pt-4 `}
  }
`;

const Logo = tw.a`w-40 h-20 p-5 hidden lg:block`;
const Logo2 = tw.a`w-40 h-20 p-5 block lg:hidden`;

const NavLinks = styled.div`
  ${tw`w-full pr-12 lg:inline-flex lg:flex-grow lg:w-auto flex flex-row   `}
`;

const Navigation = styled.nav`
  ${tw` uppercase hidden m-2 text-lg  font-inter  text-white  pt-6 font-sans font-normal md:block transition duration-300  lg:block hover:border-gray-500 hocus:text-gray-500  border-b-2 border-transparent cursor-pointer`}
`;

const Navbar = ({ toggle, isOpen }) => {
  return (
    <div>
      <Nav>
        <Logo>
          <a href='/' className='logo'>
            <img src={COAM} alt='coam-logo' />
          </a>
        </Logo>
        <Logo2>
          <a href='/' className='logo'>
            <img src={COAMICON} alt='coam-logo' />
          </a>
        </Logo2>
        {isOpen ? (
          <img
            src={closeicon}
            alt='close'
            onClick={toggle}
            className='menubar'
          />
        ) : (
          <img
            src={menubar}
            alt='menubar'
            onClick={toggle}
            className='menubar'
          />
        )}

        <div>
          <NavLinks>
            <Navigation>
              <a href='#services'>services</a>
            </Navigation>
            <Navigation>
              <a href='#about'>about us</a>
            </Navigation>
            <Navigation>
              <a href='#getstarted'>get started</a>
            </Navigation>
          </NavLinks>
        </div>
      </Nav>
    </div>
  );
};

export default Navbar;
