import Catalogue from '../images/catalogue 1.svg';
import Speed from '../images/speed 1.svg';
import Bar from '../images/bar-chart 1.svg';
import Marketing from '../images/megaphone 1.svg';

export const cards = [
  {
    id: 1,
    img: Catalogue,
    title: 'Catalogue',
    desc: 'Uploading products online can be frustrating - we help you quickly upload   selection, hassle free. We offer a broad range of catalogue services, from keyword optimisation, to image uploads, to enhanced SEO.  ',
  },
  {
    id: 2,
    img: Speed,
    title: 'Optimization',
    desc: 'Simply uploading products online is never enough: we provide ongoing services to optimise your selection using tailored SEO and PPC that help customers find your products more easily, improving conversion.',
  },
  {
    id: 3,
    img: Bar,
    title: 'Analytics',
    desc: 'Numbers don’t lie. We provide insights into your products launches: from tailored keyword research to custom bid strategies to data-driven approaches on how to increase conversion and product ranking. ',
  },
  {
    id: 4,
    img: Marketing,
    title: 'Marketing',
    desc: 'Digital marketing is the foundation of any effective product launch. We help you design custom and targeted launch strategies to drive demand and improve conversion of your products, from SEM to programmatic marketing. ',
  },
];
