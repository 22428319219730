import React, { useState } from 'react'
import Slider from 'react-slick'
import tw from 'twin.macro'
import styled from 'styled-components'
import { IoIosArrowForward as ChevronRightIcon } from 'react-icons/io'
import { IoIosArrowBack as ChevronLeftIcon } from 'react-icons/io'

const PrimaryButtonBase = tw.button`font-bold text-white rounded bg-gray-300 py-3 px-6`

const Container = tw.div`relative font-sans text-black`
const Content = tw.div`max-w-full mx-auto pb-16`

const Controls = tw.div`invisible mt--28 sm:mt--14 md:visible lg:invisible md:float-right`
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`

const NextButton = tw(ControlButton)``
const PrevButton = tw(ControlButton)``

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`
const Card = tw.div`shadow-sm flex! flex-col mx-auto max-w-sm mt-8 sm:border relative focus:outline-none p-8 sm:rounded-lg lg:shadow-lg border-2 sm:border-0`
const CardImage = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center rounded-md`,
])

const Description = tw.p`text-base font-light text-center leading-loose mt-2 sm:mt-4 lg:h-72  leading-7`

const Text = tw.div`mt-7 text-2xl font-normal text-center font-semibold`

const Cardslider = () => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null)
  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: 'unslick',
      },
    ],
  }

  /* Change this according to your needs */
  const cards = [
    {
      imageSrc: 'https://coam.s3.us-east-2.amazonaws.com/stats1.png',
      title: 'Tier 1: Sell',
      description:
        'Whether you’ve never uploaded a product online, need help expanding your catalogue or require keyword research and competitor analysis to stand out from the competition, Tier 1 is the foundation for any successful product launch. We help you navigate the challenges of catalogue creation and management with data-driven suggestions.',
    },
    {
      imageSrc: 'https://coam.s3.us-east-2.amazonaws.com/stats2.png',
      title: 'Tier 2: Convert',
      description:
        'If the customer isn’t buying from you, they’re buying from someone else. Tier 2 builds on Tier 1 and you’ll receive expert guidance on product page development, SEO and SEM, and how to create targeted advertising campaigns to help you win the Buy Box. We also  provide you with custom reports so you can closely monitor your ROI.',
    },
    {
      imageSrc: 'https://coam.s3.us-east-2.amazonaws.com/stats3.png',
      title: 'Tier 3: Excel',
      description:
        'Tier 3 allows us to do what we do best: grow your business. In addition to Tier 1 and Tier 2 services, we not only manage your account, but work closely with you to develop a custom e-commerce sales strategy, own customer service management, provide inventory forecasting and proactively make recommendations on how you can grow your e-commerce presence across all platforms.',
    },
  ]

  return (
    <Container>
      <Content>
        <Controls>
          <PrevButton onClick={sliderRef?.slickPrev}>
            <ChevronLeftIcon />
          </PrevButton>
          <NextButton onClick={sliderRef?.slickNext}>
            <ChevronRightIcon />
          </NextButton>
        </Controls>
        <CardSlider ref={setSliderRef} {...sliderSettings}>
          {cards.map((card, index) => (
            <Card key={index}>
              <CardImage imageSrc={card.imageSrc} />
              <Text>{card.title}</Text>
              <Description>{card.description}</Description>
            </Card>
          ))}
        </CardSlider>
      </Content>
    </Container>
  )
}

export default Cardslider
