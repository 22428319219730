import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import PhoneIcon from '../images/call.png'
import EmailIcon from '../images/mail.png'

const Section = tw.div`text-gray-500 bg-footerBg  `
const Container = tw.div`container px-5 py-12 lg:py-8 mx-auto  `
const InnerContainer = tw.div`flex flex-wrap md:text-left text-center -mb-10 -mx-4 `
const InnerContainer2 = tw.div`lg:pt-10 justify-center  `
const FlexSection = tw.div`container px-5 py-8 flex flex-wrap mx-auto items-center justify-center`
const NoWrapClass = tw.div`flex md:flex-nowrap flex-wrap justify-center items-end md:justify-start text-footerFont`
const RelativeContainer = tw.div`relative sm:w-64 w-full sm:mr-4 mr-2 text-center lg:text-left lg:mt-8 mt-3`
const Span = tw.span`inline-flex lg:ml-auto lg:mt-0 mt-6 w-full justify-center md:justify-start md:w-auto `
const SocialLinksContainer = tw.div`items-center text-center lg:text-right  `
const SocialLink = styled.a`
  ${tw`  cursor-pointer inline-block p-2  text-gray-900 transition duration-300 mr-4 last:mr-0  `}
  svg {
    ${tw`w-4 h-4`}
  }
`
const PhoneNumeber = tw.p`w-full  items-center text-footerFont lg:text-right mb-5 lg:mb-2`
const Email = tw.p`w-full text-footerFont  items-center text-center lg:text-right mb-5 lg:mb-3`

const Footer = () => {
  return (
    <div>
      <Section>
        <Container>
          <InnerContainer>
            <InnerContainer2>
              <div
                class='flex items-center justify-center lg:justify-start
              '
              >
                <img
                  src='https://i.ibb.co/BZ6S2g6/COAM-Logo-Transparent-Background-2.png'
                  width='200'
                  height='500'
                ></img>
              </div>
              <NoWrapClass>
                <RelativeContainer>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting
                </RelativeContainer>
              </NoWrapClass>
            </InnerContainer2>
            <Span>
              <InnerContainer2>
                <FlexSection>
                  <NoWrapClass>
                    <RelativeContainer>
                      <PhoneNumeber>
                        <img
                          class='float-right hidden lg:flex'
                          src={PhoneIcon}
                          width='20'
                          height='20'
                        />
                        <h1 class='lg:mr-8'>0845796335920</h1>
                      </PhoneNumeber>
                      <Email>
                        <img
                          class='float-right hidden lg:flex'
                          src={EmailIcon}
                          width='20'
                          height='20'
                        />
                        <h1 class='lg:mr-8'>coam@coam.com</h1>
                      </Email>
                      <SocialLinksContainer>
                        {/* <SocialLink href='https://facebook.com'>
                          <img
                            src='https://i.ibb.co/F8MKj5C/facebook.png'
                            // alt='facebook'
                            width='32'
                            height='50'
                            tw='pb-5'
                          ></img>
                        </SocialLink> */}
                        {/* <SocialLink href='https://twitter.com'>
                          <img
                            src='https://i.ibb.co/6bG3X9Z/twitter.png'
                            width='50'
                            height='50'
                          ></img>
                        </SocialLink> */}
                        <SocialLink
                          href='https://www.instagram.com/coamlimited/'
                          target='_blank'
                        >
                          {/* <YoutubeIcon /> */}
                          <img
                            src='https://i.ibb.co/FqPfY8M/instagram.png'
                            width='50'
                            height='50'
                            // width='100'
                            // height='100'
                          ></img>
                        </SocialLink>
                      </SocialLinksContainer>
                    </RelativeContainer>
                  </NoWrapClass>
                </FlexSection>
              </InnerContainer2>
            </Span>
          </InnerContainer>
        </Container>
      </Section>
    </div>
  )
}

export default Footer
