import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';


import { BrowserRouter } from 'react-router-dom';
import Dropdown from './components/Dropdown';
import styled from 'styled-components';
import Home from './pages/home';

function App() {
  useEffect(() => {
    ReactGA.initialize('UA-208030938-3');
    // To Report Page View 
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])

  useEffect(() => {
   console.log(window.location.pathname)
  })
  // const [isOpen, setIsOpen] = useState(false);

  // const toggle = () => {
  //   setIsOpen(!isOpen);
  // };

  // useEffect(() => {
  //   const hideMenu = () => {
  //     if (window.innerWidth > 768 && isOpen) {
  //       setIsOpen(false);
  //     }
  //   };
  //   window.addEventListener('resize', hideMenu);

  //   return () => {
  //     window.removeEventListener('resize', hideMenu);
  //   };
  // }, [isOpen]);
  return (
    <>
      <BrowserRouter>
        <Main>
          {/* <Dropdown isOpen={isOpen} toggle={toggle} /> */}
          <Home />
          {/* <Route exact path='/' component={Home} />
          <Route path='/services' component={services} />
          <Route path='/contact' component={contact} />
          <Route path='/about' component={about} /> */}
        </Main>
      </BrowserRouter>
    </>
  );
}
const Main = styled.div`
  padding: 0;
  margin: 0;
  overflow: hidden;
`;
export default App;
