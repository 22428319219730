import React from 'react'
import Hero from '../components/Hero'
import Services from './services'
import Contact from './contact'
import About from './about'
import Footer from '../components/Footer.js'

const GA = require('../helpers/googleAnalytics')
const Home = () => {
  return (
    <>
      {GA.logPageView()}
      <Hero />

      <Services />
      <About />
      <Contact />
      <Footer />
    </>
  )
}

export default Home
